import { createRoot } from 'react-dom/client'
import './styles.css'
import App from './App'
import { Logo } from '@pmndrs/branding'

function Overlay() {
  // return (
  //   <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
  //     <a href="https://pmnd.rs/" style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px' }}>
  //       pmnd.rs
  //       <br />
  //       dev collective
  //     </a>
  //     <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>ok —</div>
  //     <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>16/12/2022</div>
  //   </div>
  // )
}

createRoot(document.getElementById('root')).render(
  <>
    <App />
    <Overlay />
    <Logo style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} />
  </>
)

import { useRef, useState } from 'react'

import { useCursor } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export function SpinningBox({ scale, ...props }) {
  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef()
  // Hold state for hovered and clicked events
  const [hovered, hover] = useState(false)
  const [clicked, click] = useState(false)

  const getRandomUrl = () => {
    const urls = ['https://atom.osuolfou.com', 'https://pictures.osuolfou.com']
    const randomIndex = Math.floor(Math.random() * urls.length)
    return urls[randomIndex]
  }

  const handleOnClick = () => {
    const randomUrl = getRandomUrl()
    setTimeout(() => {
      window.location.href = randomUrl
    }, 1000)
  }

  useCursor(hovered)
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => (ref.current.rotation.x = ref.current.rotation.y += delta))
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <mesh
      {...props}
      ref={ref}
      scale={clicked ? scale * 1.4 : scale * 1.2}
      onClick={(event) => {
        click(!clicked)
        handleOnClick()
      }}
      onPointerOver={(event) => hover(true)}
      onPointerOut={(event) => hover(false)}>
      <boxGeometry />
      <meshStandardMaterial color={hovered ? 'hotpink' : 'indianred'} />
    </mesh>
  )
}
